import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

import { Button, Form, Spinner } from "react-bootstrap";

import swal from "sweetalert";

import axiosInstance from "../../services/AxiosInstance";

import { useAuth } from "../../providers/use-auth";

import { Link } from "react-router-dom";

function Login(props) {
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [email, setEmail] = useState("");

  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);

  //firebase
  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onDone = async (e) => {
    e.preventDefault();
    setProgress(false);
    setSuccess(false);
    setEmail("");
  };

  const sendResetMail = async (e) => {
    e.preventDefault();
    setProgress(true);

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      //axios.post("/api/auth/signIn",fd,config);
      const { data } = await axiosInstance.post(
        "auth/forgotPassword",
        { email },
        config
      );

      setProgress(false);

      if (!data.success) {
        swal("Forgot Password", data.message, "success");
      } else {
        setSuccess(true);
        //toast.error(data.message);
      }
    } catch (error) {
      setProgress(false);

      swal("Oops", error.response.data.error, "error");
      setEmail("");
      setTimeout(() => {
        //setError("");
      }, 5000);
    }

    /* e.preventDefault();
      setProgress(true);
    
      console.log("setNewPassword");
    
      let response = await auth.sendPasswordResetEmail(email);
    
        if(response){
        setMainSent(true);
        toast("Password reset code sent on "+email);
        setProgress(false);
        return;
      }else{
        toast.error("Password reset code send failed!");
        setProgress(false);
      } */
  };

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url('https://dashboard.tap1ce.com/api/media/background.svg')" }}
    >
      <div className="login-wrapper">
        <div className="login-aside-left">
          <div className="login-description">
            <div className="img-shadow">
              <img
                class="img img-fluid mt-0 image-shadow"
                width={350}
                src={process.env.REACT_APP_LOGO}
                alt=""
              />
            </div>

            <h2 className="main-title mb-2 mt-5">
              Welcome To {process.env.REACT_APP_NAME}
            </h2>
            <p className=""></p>
            <ul className="social-icons mt-4">
              <li>
                <a href={process.env.REACT_APP_URL}>
                  <i className="fa fa-globe"></i>
                </a>
              </li>
            </ul>
            <div className="mt-3 bottom-privacy">
              <p>
                Copyright © Designed & Developed by{" "}
                <a
                  href={process.env.REACT_APP_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_BY}
                </a>{" "}
                2023
              </p>
            </div>
          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="p-5">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="dz-title mb-1">Forgot Password</h3>
                        {!success ? (
                          <p className="">
                            Please enter the email address you register your
                            account with. We will send you reset password
                            confirmation to this email
                          </p>
                        ) : (
                          <p className="">
                            An email has been sent to your email address {email}
                            . Follow the direction in the email to reset your
                            password.
                          </p>
                        )}
                      </div>
                      {props.errorMessage && (
                        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}

                      {!success ? (
                        <Form onSubmit={sendResetMail}>
                          <Form.Group controlId="formEmail mt-4">
                            <label className="mb-2 ">
                              <strong>Mail Id</strong>
                            </label>

                            <Form.Control
                              className="main"
                              type="email"
                              placeholder="Email Address"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>

                          <div className="form-group">
                            <label className="mb-2 d-flex flex-wrap align-items-center">
                              <strong className="mr-auto">
                                Already have an account?
                              </strong>{" "}
                              <Link to="/login">Login</Link>{" "}
                            </label>
                          </div>

                          {progress ? (
                            <div className="text-center">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          ) : (
                            <Button
                              variant="primary"
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Confirm
                            </Button>
                          )}
                        </Form>
                      ) : (
                        <Button
                          variant="primary"
                          onClick={onDone}
                          type="button"
                          className="mt-4 btn btn-primary btn-block"
                        >
                          Done
                        </Button>
                      )}

                      <div id="recaptcha-container"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
