import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import swal from "sweetalert";
/// Scroll

import LogoutPage from "./Logout";

import {
  Row,
  Col,
  Table,
  Modal,
  Dropdown,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";

import axiosInstance from "../../../services/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";

import { profileAction } from "../../../store/actions/AuthActions";
import { useAuth } from "../../../providers/use-auth";

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);

  const fireAuth = useAuth();

  //change password
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const [errors, setErrors] = useState({});

  const [applyModal, setApplyModal] = useState(false);

  const [needCards, setNeedCards] = useState(1);

  const profile = useSelector((state) => state.auth.profile);

  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  useEffect(() => {
    loadProfile();
    //dispatch(getProfile());
  }, [dispatch]);

  useEffect(() => {
    if (needCards) {
      setErrors({});
    }
  }, [needCards]);

  async function loadProfile() {
    try {
      const { data } = await axiosInstance.get("auth/profile");

      console.log("profile data - " + JSON.stringify(data));

      if (data.status === false) {
      } else {
        //setUser(data.user);
        dispatch(profileAction(data.user));
      }
    } catch (error) {}
  }

  const onApplyFormSubmit = async () => {
    let error = false;
    const errorObj = {};
    if (needCards === "") {
      errorObj.needCards = "Total cards is required!";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    try {
      const { data } = await axiosInstance.post("corporate/apply-for-card", {
        cards: needCards,
      });

      console.log("profile data - " + JSON.stringify(data));

      if (data.status === false) {
        swal("Apply Cards", data.message, "error");
      } else {
        swal("Apply Cards", data.message, "success");
        setApplyModal(false);
      }
    } catch (error) {
      swal("Apply Cards", error.message, "error");
    }
  };

  const onChangePassword = async (e) => {
    setNewPassword("");
    setOldPassword("");
    setChangePasswordModal(true);
  };

  const onChangePasswordSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = {};

    // if (oldPassword === "") {
    //   errorObj.oldPassword = "Old Password is Required";
    //   error = true;
    // }

    if (newPassword === "") {
      errorObj.newPassword = "Phone Number is Required";
      error = true;
    } else if (newPassword.length <= 7) {
      errorObj.newPassword = "New Password is must be 8 character";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }

    setChangePasswordLoading(true);
    const response = await fireAuth.changePassword(newPassword);

    if (response.status) {
      swal("Change Password", response.message, "success");

      setChangePasswordModal(false);
    } else {
      swal("Change Password", response.message, "error");
    }

    setChangePasswordLoading(false);
  };

  return (
    <>
      <Modal
        className="modal fade"
        show={changePasswordModal}
        onHide={setChangePasswordModal}
      >
        <div className="" role="document">
          <div className="content">
            <Form onSubmit={onChangePasswordSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Change Password</h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setChangePasswordModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <Form.Group controlId="formPassword">
                  <label className="mb-2 required-field">
                    <strong>New Password</strong>
                  </label>
                  <Form.Control
                    className="main"
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />

                  {errors.newPassword && (
                    <div className="text-danger fs-12">
                      {errors.newPassword}
                    </div>
                  )}
                </Form.Group>

                <div className="modal-footer">
                  <div className="align-self-center mx-auto">
                    {changePasswordLoading ? (
                      <Spinner animation="border" variant="primary" />
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Change
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>

      <Modal show={applyModal} onHide={setApplyModal} size="md">
        <Modal.Header>
          <Modal.Title>Apply For Cards</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setApplyModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">
                How many cards do you need?
              </label>
              <div className="contact-name">
                <input
                  type="number"
                  className="form-control"
                  required="required"
                  value={needCards}
                  onChange={(e) => setNeedCards(e.target.value)}
                  placeholder="Cards"
                  min={1}
                />
                <span className="validation-text">
                  {errors.needCards && (
                    <div className="text-danger fs-12">{errors.needCards}</div>
                  )}
                </span>

                <p>
                  {" "}
                  <b>Price: </b> ₹1299/unit (incl GST) = ₹
                  {(needCards ?? 0) * 1299}/- Total
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger light" onClick={() => setApplyModal(false)}>
            Close
          </Button>
          <Button
            variant=""
            type="button"
            className="btn btn-primary"
            onClick={(e) => onApplyFormSubmit()}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div
                  className="dashboard_bar"
                  style={{ textTransform: "capitalize" }}
                >
                  {finalName.join(" ").length === 0
                    ? "Dashboard"
                    : finalName.join(" ")}
                </div>
              </div>
              <ul className="navbar-nav header-right">
                <Dropdown as="li" className="nav-item card-dropdown ">
                  <Dropdown.Toggle
                    as="a"
                    to="#"
                    variant=""
                    className="nav-link i-false c-pointer"
                  >
                    <Button
                      onClick={(e) => setApplyModal(true)}
                      variant="primary"
                    >
                      Apply For Cards
                    </Button>
                  </Dropdown.Toggle>
                </Dropdown>

                <Dropdown as="li" className="nav-item card-dropdown ">
                  <Dropdown.Toggle
                    as="a"
                    to="#"
                    variant=""
                    className="nav-link i-false c-pointer"
                  >
                    <Button variant="primary">
                      {profile ? profile.usedCards : "--"} Card Used
                    </Button>
                  </Dropdown.Toggle>
                </Dropdown>

                <Dropdown as="li" className="nav-item card-dropdown ">
                  <Dropdown.Toggle
                    as="a"
                    to="#"
                    variant=""
                    className="nav-link i-false c-pointer"
                  >
                    <Button variant="primary">
                      {profile ? profile.cards : "--"} Card Available
                    </Button>
                  </Dropdown.Toggle>
                </Dropdown>

                <Dropdown as="li" className="nav-item header-profile ">
                  <Dropdown.Toggle
                    as="a"
                    to="#"
                    variant=""
                    className="nav-link i-false c-pointer"
                  >
                    <img
                      src="https://dashboard.tap1ce.com/api/media/administrator.png"
                      width="20"
                      alt=""
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="right" className="mt-2">
                    <Link to="/my-profile" className="dropdown-item ai-icon">
                      <svg
                        id="icon-user1"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-primary"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                        <circle cx={12} cy={7} r={4} />
                      </svg>
                      <span className="ml-2">Profile </span>
                    </Link>

                    <a
                    href="#"
                      className="dropdown-item ai-icon"
                      onClick={(e) => onChangePassword()}
                    >
                      <i className="flaticon-381-lock-3"></i>
                      <span className="ml-2">Change Password </span>
                    </a>

                    <LogoutPage />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
